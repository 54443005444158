define('ember-math-helpers/helpers/acos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.acos = acos;


  /**
   * Executes `Math.acos` on the number passed to the helper.
   *
   * ```hbs
   * {{acos a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.acos`
   * @return {number} The acos of the passed number
   */
  function acos([number]) {
    return Math.acos(number);
  }

  exports.default = Ember.Helper.helper(acos);
});