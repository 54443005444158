define('ember-cp-validations/validators/base', ['exports', 'ember-cp-validations/validators/messages', 'ember-cp-validations/-private/options', 'ember-cp-validations/utils/utils'], function (exports, _messages, _options, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  const {
    get,
    set,
    isNone,
    computed,
    getOwner
  } = Ember;

  /**
   * @class Base
   * @module Validators
   */
  const Base = Ember.Object.extend({

    /**
     * Options passed in to the validator when defined in the model
     * @property options
     * @type {Object}
     */
    options: null,

    /**
     * Default validation options for this specific attribute
     * @property defaultOptions
     * @type {Object}
     */
    defaultOptions: null,

    /**
     * Global validation options for this model
     * @property globalOptions
     * @type {Object}
     */
    globalOptions: null,

    /**
     * Model instance
     * @property model
     * @type {Model}
     */
    model: null,

    /**
     * Attributed name of the model this validator is attached to
     * @property attribute
     * @type {String}
     */
    attribute: null,

    /**
     * Error message object. Populated by validators/messages
     * @property errorMessages
     * @type {Object}
     */
    errorMessages: null,

    /**
     * @property isWarning
     * @type {Boolean}
     */
    isWarning: computed.bool('options.isWarning').readOnly(),

    /**
     * Validator type
     * @property _type
     * @private
     * @type {String}
     */
    _type: null,

    init() {
      this._super(...arguments);
      let globalOptions = get(this, 'globalOptions');
      let defaultOptions = get(this, 'defaultOptions');
      let options = get(this, 'options');
      let owner = getOwner(this);
      let errorMessages;

      if (!isNone(owner)) {
        // Since default error messages are stored in app/validators/messages, we have to look it up via the owner
        errorMessages = owner.factoryFor('validator:messages');
      }

      // If for some reason, we can't find the messages object (i.e. unit tests), use default
      errorMessages = errorMessages || _messages.default;

      set(this, 'options', this.buildOptions(options || {}, defaultOptions || {}, globalOptions || {}));
      set(this, 'errorMessages', errorMessages.create());
    },

    /**
     * Build options hook. Merges default options into options object.
     * This method gets called on init and is the ideal place to normalize your options.
     * The [presence validator](https://github.com/offirgolan/ember-cp-validations/blob/master/app/validators/presence.js) is a good example to checkout
     * @method buildOptions
     * @param  {Object} options
     * @param  {Object} defaultOptions
     * @param  {Object} globalOptions
     * @return {Object}
     */
    buildOptions(options = {}, defaultOptions = {}, globalOptions = {}) {
      let builtOptions = (0, _utils.mergeOptions)(options, defaultOptions, globalOptions);

      // Overwrite the validator's value method if it exists in the options and remove it since
      // there is no need for it to be passed around
      this.value = builtOptions.value || this.value;
      delete builtOptions.value;

      return _options.default.create({
        model: get(this, 'model'),
        attribute: get(this, 'attribute'),
        __options__: builtOptions
      });
    },

    /**
     * Used to retrieve the value to validate.
     * This method gets called right before `validate` and the returned value
     * gets passed into the validate method.
     *
     * @method value
     * @param {Object} model
     * @param {String} attribute
     * @return The current value of `model[attribute]`
     */
    value(model, attribute) {
      return get(model, attribute);
    },

    /**
     * Wrapper method to `value` that passes the necessary parameters
     *
     * @method getValue
     * @private
     * @return {Mixed} value
     */
    getValue() {
      let value = this.value(get(this, 'model'), get(this, 'attribute'));
      return (0, _utils.getValidatableValue)(value);
    },

    /**
     * The validate method is where all of your logic should go.
     * It will get passed in the current value of the attribute this validator is attached to.
     * Within the validator object, you will have access to the following properties:
     * @method validate
     * @param  {Mixed} value        The current value of the attribute
     * @param  {Object} options       The built and processed options
     * @param  {Object} model         The current model being evaluated
     * @param  {String} attribute     The current attribute being evaluated
     * @return
     * One of the following types:
     * - `Boolean`:  `true` if the current value passed the validation
     * - `String`: The error message
     * - `Promise`: A promise that will either resolve or reject, and will finally return either `true` or the final error message string.
     */
    validate() {
      return true;
    },

    /**
     * Used by all pre-defined validators to build an error message that is present
     * in `validators/message` or declared in your i18n solution.
     *
     * If we extended our default messages to include `uniqueUsername: '{username} already exists'`,
     * we can use this method to generate our error message.
     *
     * ```javascript
     * validate(value, options) {
     *   var exists = false;
     *
     *   get(options, 'description') = 'Username';
     *   get(options, 'username') = value;
     *
     *   // check with server if username exists...
     *
     *   if(exists) {
     *     return this.createErrorMessage('uniqueUsername', value, options);
     *   }
     *
     *   return true;
     * }
     * ```
     *
     * If we input `johndoe` and that username already exists, the returned message would be `'johndoe already exists'`.
     *
     * @method createErrorMessage
     * @param  {String} type        The type of message template to use
     * @param  {Mixed} value                Current value being evaluated
     * @param  {Object} options     Validator built and processed options (used as the message string context)
     * @return {String}             The generated message
     */
    createErrorMessage(type, value, options = {}) {
      let messages = this.get('errorMessages');
      let message = (0, _utils.unwrapString)(get(options, 'message'));

      set(options, 'description', messages.getDescriptionFor(get(this, 'attribute'), options));

      if (message) {
        if (typeof message === 'string') {
          message = messages.formatMessage(message, options);
        } else if (typeof message === 'function') {
          message = message.apply(this, arguments);
          message = isNone(message) ? messages.getMessageFor(type, options) : messages.formatMessage(message, options);
        }
      } else {
        message = messages.getMessageFor(type, options);
      }

      return message.trim();
    }
  });

  Base.reopenClass({
    /**
     * Generate the needed depenent keys for this validator
     *
     * @method getDependentsFor
     * @static
     * @param  {String} attribute
     * @param  {Object} options
     * @return {Array} dependent keys
     */
    getDependentsFor() {
      return [];
    }
  });

  exports.default = Base;
});