define('ember-validators/utils/is-promise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isPromise;


  const {
    canInvoke
  } = Ember; /**
              * Copyright 2016, Yahoo! Inc.
              * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
              */

  function isPromise(p) {
    return !!(p && canInvoke(p, 'then'));
  }
});