define("ember-cli-materialize/components/md-parallax", ["exports", "ember-cli-materialize/templates/components/md-parallax"], function (_exports, _mdParallax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _mdParallax.default,
    classNames: ['parallax-container'],

    didInsertElement() {
      this._super(...arguments);

      this._setupParallax();
    },

    _setupParallax() {
      this.$('.parallax').parallax();
    } // TODO: unregister any listeners that $.parallax() registers
    // _teardownParallax() {
    //
    // }


  });

  _exports.default = _default;
});