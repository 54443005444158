define("ember-cli-materialize/components/selectable-item-group", ["exports", "ember-composability/mixins/parent-component-support", "ember-cli-materialize/templates/components/selectable-item-group"], function (_exports, _parentComponentSupport, _selectableItemGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_parentComponentSupport.default, {
    layout: _selectableItemGroup.default,
    content: null,
    selection: null,
    optionValuePath: 'content',
    optionLabelPath: 'content',
    multiple: false,
    __materializeSelectableItemGroup: true,

    init() {
      this._super(...arguments);

      if (this.get('selection') === null && !!this.get('multiple')) {
        this.set('selection', new Ember.A([]));
      }
    },

    isValueSelected(value) {
      if (this.get('multiple')) {
        return this.get('selection').indexOf(value) >= 0;
      } else {
        return this.get('selection') === value;
      }
    },

    setValueSelection(value, select) {
      if (select) {
        return this.addToSelection(value);
      } else {
        return this.removeFromSelection(value);
      }
    },

    addToSelection(value) {
      if (this.get('multiple')) {
        this.get('selection').addObject(value);
      } else {
        this.set('selection', value);
      }
    },

    removeFromSelection(value) {
      if (this.get('multiple')) {
        this.get('selection').removeObject(value);
      } else {
        if (this.get('selection') === value) {
          this.set('selection', null);
        }
      }
    },

    disabled: false,
    _valuePath: Ember.computed('optionValuePath', function () {
      const optionValuePath = Ember.get(this, 'optionValuePath');
      return optionValuePath.replace(/^content\.?/, '');
    }),
    _labelPath: Ember.computed('optionLabelPath', function () {
      const optionLabelPath = Ember.get(this, 'optionLabelPath');
      return optionLabelPath.replace(/^content\.?/, '');
    }),
    _content: Ember.computed('content.[]', '_valuePath', '_labelPath', function () {
      const valuePath = Ember.get(this, '_valuePath');
      const labelPath = Ember.get(this, '_labelPath');
      const content = Ember.get(this, 'content') || new Ember.A([]);

      if (valuePath && labelPath) {
        return Ember.A(content.map(el => {
          return {
            value: Ember.get(el, valuePath),
            label: Ember.get(el, labelPath)
          };
        }));
      } else {
        return Ember.A(content.map(el => {
          return {
            value: el,
            label: el
          };
        }));
      }
    })
  });

  _exports.default = _default;
});