define('ember-math-helpers/helpers/hypot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hypot = hypot;


  /**
   * Takes two or more numbers, and returns the square root of the sum of squares of them using `Math.hypot`
   *
   * ```hbs
   * {{max a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.hypot`
   * @return {number} The hypot of the set of numbers
   */
  function hypot(numbers) {
    return Math.hypot(...numbers);
  }

  exports.default = Ember.Helper.helper(hypot);
});