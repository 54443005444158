define('ember-validators/utils/unwrap-proxy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = unwrapProxy;
  exports.isProxy = isProxy;


  const {
    get
  } = Ember; /**
              * Copyright 2016, Yahoo! Inc.
              * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
              */
  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy(get(o, 'content')) : o;
  }

  function isProxy(o) {
    return !!(o && (o instanceof Ember.ObjectProxy || o instanceof Ember.ArrayProxy));
  }
});