define('ember-math-helpers/helpers/tan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tan = tan;


  /**
   * Executes `Math.tan` on the number passed to the helper.
   *
   * ```hbs
   * {{tan a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.tan`
   * @return {number} The tan of the passed number
   */
  function tan([number]) {
    return Math.tan(number);
  }

  exports.default = Ember.Helper.helper(tan);
});