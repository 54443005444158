define('ember-math-helpers/helpers/sub', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sub = sub;


  /**
   * Subtracts two or more numbers
   *
   * ```hbs
   * {{sub a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to subtract
   * @return {number} The difference of all the passed numbers
   */
  function sub(numbers) {
    return numbers.reduce((a, b) => Number(a) - Number(b));
  }

  exports.default = Ember.Helper.helper(sub);
});