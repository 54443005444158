define('ember-math-helpers/helpers/max', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.max = max;


  /**
   * Takes two or more numbers, and finds the max of the set using `Math.max`
   *
   * ```hbs
   * {{max a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.max`
   * @return {number} The max of the set of numbers
   */
  function max(numbers) {
    return Math.max(...numbers);
  }

  exports.default = Ember.Helper.helper(max);
});