define('ember-math-helpers/helpers/floor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.floor = floor;


  /**
   * Executes `Math.floor` on the number passed to the helper.
   *
   * ```hbs
   * {{floor a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.floor`
   * @return {number} The floor of the passed number
   */
  function floor([number]) {
    return Math.floor(number);
  }

  exports.default = Ember.Helper.helper(floor);
});