define('ember-math-helpers/helpers/cosh', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cosh = cosh;


  /**
   * Executes `Math.cosh` on the number passed to the helper.
   *
   * ```hbs
   * {{cosh a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.cosh`
   * @return {number} The cosh of the passed number
   */
  function cosh([number]) {
    return Math.cosh(number);
  }

  exports.default = Ember.Helper.helper(cosh);
});