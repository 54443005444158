define('ember-math-helpers/helpers/trunc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.trunc = trunc;


  /**
   * Executes `Math.trunc` on the number passed to the helper.
   *
   * ```hbs
   * {{trunc a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.trunc`
   * @return {number} The trunc of the passed number
   */
  function trunc([number]) {
    return Math.trunc(number);
  }

  exports.default = Ember.Helper.helper(trunc);
});