define('ember-math-helpers/helpers/clz32', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.clz32 = clz32;


  /**
   * Executes `Math.clz32` on the number passed to the helper.
   *
   * ```hbs
   * {{clz32 a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.clz32`
   * @return {number} The clz32 of the passed number
   */
  function clz32([number]) {
    return Math.clz32(number);
  }

  exports.default = Ember.Helper.helper(clz32);
});