define('ember-math-helpers/helpers/cos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cos = cos;


  /**
   * Executes `Math.cos` on the number passed to the helper.
   *
   * ```hbs
   * {{cos a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.cos`
   * @return {number} The cos of the passed number
   */
  function cos([number]) {
    return Math.cos(number);
  }

  exports.default = Ember.Helper.helper(cos);
});