define("ember-cli-materialize/components/md-input", ["exports", "ember-cli-materialize/components/md-input-field", "ember-cli-materialize/templates/components/md-input"], function (_exports, _mdInputField, _mdInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mdInputField.default.extend({
    layout: _mdInput.default,
    type: 'text',

    didInsertElement() {
      this._super(...arguments); // make sure the label moves when a value is bound.


      this._setupLabel();
    }

  });

  _exports.default = _default;
});