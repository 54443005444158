define('ember-cp-validations/utils/meta-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let id = 0; /**
               * Copyright 2016, Yahoo! Inc.
               * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
               */

  const dataKey = symbol('data');

  function symbol(key) {
    return `_${key}_${new Date().getTime()}_${id++}`;
  }

  function getData(obj, s) {
    let m = Ember.meta(obj);
    let data = m[dataKey];

    if (data) {
      return data[s];
    }
  }

  function setData(obj, s, value) {
    let m = Ember.meta(obj);
    let data = m[dataKey] = m[dataKey] || {};

    data[s] = value;
  }

  exports.default = { symbol, getData, setData };
});