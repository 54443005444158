define('ember-math-helpers/helpers/cbrt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cbrt = cbrt;


  /**
   * Executes `Math.cbrt` on the number passed to the helper.
   *
   * ```hbs
   * {{cbrt a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.cbrt`
   * @return {number} The cbrt of the passed number
   */
  function cbrt([number]) {
    return Math.cbrt(number);
  }

  exports.default = Ember.Helper.helper(cbrt);
});