define('ember-math-helpers/helpers/atan2', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.atan2 = atan2;


  /**
   * Executes `Math.atan2` on the numbers passed to the helper.
   *
   * ```hbs
   * {{atan2 a b}}
   * ```
   *
   * @param {number} number1 The first number to pass to `Math.atan2`
   * @param {number} number2 The second number to pass to `Math.atan2`
   * @return {number} The atan2 of the passed numbers
   */
  function atan2([number1, number2]) {
    return Math.atan2(number1, number2);
  }

  exports.default = Ember.Helper.helper(atan2);
});