define('ember-math-helpers/helpers/min', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.min = min;


  /**
   * Takes two or more numbers, and finds the min of the set using `Math.min`
   *
   * ```hbs
   * {{min a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.min`
   * @return {number} The min of the set of numbers
   */
  function min(numbers) {
    return Math.min(...numbers);
  }

  exports.default = Ember.Helper.helper(min);
});