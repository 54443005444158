define("ember-cli-materialize/components/md-table", ["exports", "ember-composability/mixins/parent-component-support", "ember-cli-materialize/templates/components/md-table"], function (_exports, _parentComponentSupport, _mdTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_parentComponentSupport.default, {
    tagName: 'table',
    layout: _mdTable.default,
    columns: null,
    composableChildrenDebounceTime: 1,

    init() {
      this._super(...arguments);

      this.set('columns', []);
    },

    columnComponents: Ember.computed('composableChildren', function () {
      return new Ember.A(this.get('composableChildren'));
    }).readOnly(),

    registerChildComponent(childComponent) {
      this.get('_childComponents').add(childComponent, childComponent.get('key'));

      this._notifyComposableChildrenChanged();
    },

    unregisterChildComponent(childComponent) {
      this.get('_childComponents').delete(childComponent, childComponent.get('key'));

      this._notifyComposableChildrenChanged();
    }

  });

  _exports.default = _default;
});