define('ember-math-helpers/helpers/sign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sign = sign;


  /**
   * Executes `Math.sign` on the number passed to the helper.
   *
   * ```hbs
   * {{sign a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.sign`
   * @return {number} The sign of the passed number
   */
  function sign([number]) {
    return Math.sign(number);
  }

  exports.default = Ember.Helper.helper(sign);
});