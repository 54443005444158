define("ember-cli-materialize/components/selectable-item", ["exports", "ember-composability/mixins/child-component-support", "ember-cli-materialize/components/selectable-item-group", "ember-new-computed"], function (_exports, _childComponentSupport, _selectableItemGroup, _emberNewComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_childComponentSupport.default, {
    // eslint-disable-next-line
    _parentComponentTypes: [_selectableItemGroup.default],
    checked: null,
    disabled: false,
    classNames: ['materialize-selectable-item'],
    _checked: (0, _emberNewComputed.default)('checked', 'group.selection', 'group.selection.[]', {
      get() {
        let group = this.get('group');

        if (!group) {
          return this.get('checked');
        } else {
          return group.isValueSelected(this.get('value'));
        }
      },

      set(key, val) {
        let group = this.get('group');

        if (!group) {
          this.set('checked', val);
        } else {
          group.setValueSelection(this.get('value'), val);
        }

        this.sendAction('action', {
          checked: !!val
        });
        return !!val;
      }

    }),
    isSelected: Ember.computed.alias('_checked'),

    _setupLabel() {
      let [$input] = this.$('.materialize-selectable-item-input, .materialize-selectable-item-input-container input').toArray();
      let inputId = $input ? $input.id : null;
      this.$('.materialize-selectable-item-label').attr('for', inputId);
    },

    didInsertElement() {
      this._super(...arguments);

      this._setupLabel();
    },

    group: Ember.computed(function () {
      return this.nearestWithProperty('__materializeSelectableItemGroup');
    })
  });

  _exports.default = _default;
});