define('ember-math-helpers/helpers/sin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sin = sin;


  /**
   * Executes `Math.sin` on the number passed to the helper.
   *
   * ```hbs
   * {{sin a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.sin`
   * @return {number} The sin of the passed number
   */
  function sin([number]) {
    return Math.sin(number);
  }

  exports.default = Ember.Helper.helper(sin);
});