define('ember-math-helpers/helpers/div', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.div = div;


  /**
   * Divides two or more numbers
   *
   * ```hbs
   * {{div a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to divide
   * @return {number} The result of dividing all the passed numbers
   */
  function div(numbers) {
    return numbers.reduce((a, b) => Number(a) / Number(b));
  }

  exports.default = Ember.Helper.helper(div);
});