define('ember-math-helpers/helpers/ceil', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ceil = ceil;


  /**
   * Executes `Math.ceil` on the number passed to the helper.
   *
   * ```hbs
   * {{ceil a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.ceil`
   * @return {number} The ceil of the passed number
   */
  function ceil([number]) {
    return Math.ceil(number);
  }

  exports.default = Ember.Helper.helper(ceil);
});