define("ember-cli-materialize/components/md-tabs", ["exports", "ember-composability/mixins/parent-component-support", "ember-cli-materialize/templates/components/md-tabs"], function (_exports, _parentComponentSupport, _mdTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_parentComponentSupport.default, {
    layout: _mdTabs.default,
    classNames: ['materialize-tabs', 'row'],
    composableChildrenDebounceTime: 1,
    content: null,
    numTabs: Ember.computed.alias('composableChildren.length'),
    optionValuePath: 'id',
    optionLabelPath: 'title',
    colWidth: 2,
    selected: null,

    didInsertElement() {
      this._super(...arguments);

      this._updateIndicatorPosition(false);
    },

    _indicatorUpdater: Ember.observer('selected', 'content.[]', 'composableChildren.[]', function () {
      Ember.run.debounce(this, this._updateIndicatorPosition, 100);
    }),

    tabComponents() {
      return Ember.A(this.get('composableChildren')) || Ember.A();
    },

    _updateIndicatorPosition(animate = true) {
      if (!this.element) {
        return;
      }

      const [tabComponent] = (this.get('composableChildren') || []).filter(item => Ember.get(item, 'value') === this.get('selected'));
      const tabSetRect = this.element.getBoundingClientRect();

      if (tabComponent) {
        const tabRect = tabComponent.element.getBoundingClientRect();
        const cssParams = {
          left: tabRect.left - tabSetRect.left,
          right: tabSetRect.right - tabRect.right
        };

        if (!animate) {
          this.$('.indicator').css(cssParams);
        } else {
          this.$('.indicator1').velocity(cssParams, {
            duration: 150
          });
          this.$('.indicator2').velocity(cssParams, {
            duration: 150,
            delay: 40
          });
        }
      }
    },

    _content: Ember.computed('content.[]', 'optionLabelPath', 'optionValuePath', function () {
      const labelPath = this.get('optionLabelPath');
      const valuePath = this.get('optionValuePath');
      return new Ember.A((this.get('content') || []).map(contentItem => ({
        id: contentItem[valuePath],
        title: contentItem[labelPath]
      })));
    })
  });

  _exports.default = _default;
});