define("ember-cli-materialize/components/md-table-col", ["exports", "ember-cli-materialize/templates/components/md-table-col", "ember-cli-materialize/components/md-table", "ember-composability/mixins/child-component-support"], function (_exports, _mdTableCol, _mdTable, _childComponentSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_childComponentSupport.default, {
    // eslint-disable-next-line
    _parentComponentTypes: [_mdTable.default],
    tagName: 'td',
    layout: _mdTableCol.default,
    valueBindingPath: null,
    headerComponentName: 'md-default-column-header',
    header: Ember.computed.alias('valueBindingPath'),
    key: Ember.computed.alias('valueBindingPath'),
    _value: Ember.computed('valueBindingPath', 'row', function () {
      let vbp = this.get('valueBindingPath');

      if (!vbp) {
        return '';
      } else {
        return Ember.get(this.get('row'), this.get('valueBindingPath'));
      }
    })
  });

  _exports.default = _default;
});