define("ember-cli-materialize/services/md-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // jscs:disable disallowDirectPropertyAccess
  const keys = Object.keys || Ember.keys; // jscs:enable disallowDirectPropertyAccess

  var _default = Ember.Service.extend({
    // Footer
    modalIsFooterFixed: Ember.computed.oneWay('defaultModalIsFooterFixed'),
    // Button
    buttonIconPosition: Ember.computed.oneWay('defaultButtonIconPosition'),
    // Loader
    loaderSize: Ember.computed.oneWay('defaultLoaderSize'),
    loaderMode: Ember.computed.oneWay('defaultLoaderMode'),
    // Modal
    modalContainerId: Ember.computed.oneWay('defaultModalContainerId'),
    // Animation (Dropdown Button)
    dropdownInDuration: Ember.computed.oneWay('defaultDropdownInDuration'),
    dropdownOutDuration: Ember.computed.oneWay('defaultDropdownOutDuration'),

    init() {
      this._super(...arguments);

      this._setDefaults();
    },

    _setDefaults() {
      const defaults = Ember.getWithDefault(this, 'materializeDefaults', {});
      keys(defaults).map(key => {
        const classifiedKey = Ember.String.classify(key);
        const defaultKey = `default${classifiedKey}`;
        return Ember.set(this, defaultKey, defaults[key]);
      });
    }

  });

  _exports.default = _default;
});