define("ember-cli-materialize/components/md-input-date", ["exports", "ember-cli-materialize/components/md-input", "ember-cli-materialize/templates/components/md-input-date"], function (_exports, _mdInput, _mdInputDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  function formatDate(timestamp) {
    const d = new Date(timestamp);
    return `${d.getDate()} ${MONTH_NAMES[d.getMonth()]}, ${d.getFullYear()}`;
  }

  var _default = _mdInput.default.extend({
    layout: _mdInputDate.default,
    selectMonths: true,
    numberOfYears: 15,
    min: '',
    max: '',

    didInsertElement() {
      this._super(...arguments);

      this._setupPicker();
    },

    willDestroyElement() {
      this._super(...arguments);

      this._teardownPicker();
    },

    _setupPicker() {
      const datePickerOptions = this.getProperties('selectMonths', 'numberOfYears', 'min', 'max');
      datePickerOptions.selectYears = datePickerOptions.numberOfYears;

      this._onDateSet = evt => {
        if (evt.select) {
          this.set('value', formatDate(evt.select));
        }
      };

      this.$('.datepicker').pickadate(Ember.$.extend(datePickerOptions, {
        onSet: this._onDateSet
      }));
    },

    _teardownPicker() {
      const $pickadate = this.$('.datepicker').data('pickadate');

      if ($pickadate) {
        $pickadate.stop();
      }
    }

  });

  _exports.default = _default;
});