define('ember-validators/presence', ['exports', 'ember-validators/utils/validation-error', 'ember-validators/utils/unwrap-proxy'], function (exports, _validationError, _unwrapProxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validatePresence;


  const {
    assert,
    isEmpty,
    isPresent,
    getProperties
  } = Ember;

  /**
   *  @class Presence
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.presence If true validates that the given value is not empty,
   *                                   if false, validates that the given value is empty.
   * @param {Boolean} options.ignoreBlank If true, treats an empty or whitespace string as not present
   * @param {Object} model
   * @param {String} attribute
   */
  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  function validatePresence(value, options, model, attribute) {
    let { presence, ignoreBlank } = getProperties(options, ['presence', 'ignoreBlank']);
    let v = (0, _unwrapProxy.default)(value);
    let _isPresent = ignoreBlank ? isPresent(v) : !isEmpty(v);

    assert(`[validator:presence] [${attribute}] option 'presence' is required`, isPresent(presence));

    if (presence === true && !_isPresent) {
      return (0, _validationError.default)('blank', value, options);
    }

    if (presence === false && _isPresent) {
      return (0, _validationError.default)('present', value, options);
    }

    return true;
  }
});