define('torii/services/iframe', ['exports', 'torii/mixins/ui-service-mixin'], function (exports, _uiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Iframe = Ember.Object.extend(Ember.Evented, _uiServiceMixin.default, {

    openRemote(url) {
      this.remote = document.createElement('iframe');
      this.remote.src = url;
      this.remote.id = 'torii-iframe';
      var iframeParent = '.torii-iframe-placeholder';
      document.querySelector(iframeParent).appendChild(this.remote);
    },

    closeRemote() {
      this.remote.remove();
    },

    pollRemote() {
      if (document.querySelector('#torii-iframe') === null) {
        this.trigger('didClose');
      }
    }

  });

  exports.default = Iframe;
});