define('ember-math-helpers/helpers/abs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.abs = abs;


  /**
   * Uses `Math.abs` to take the absolute value of the number passed to the helper.
   *
   * ```hbs
   * {{abs a}}
   * ```
   *
   * @param {number} number The number to take the absolute value of
   * @return {number} The absolute value of the passed number
   */
  function abs([number]) {
    return Math.abs(number);
  }

  exports.default = Ember.Helper.helper(abs);
});