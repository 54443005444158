define('ember-cp-validations/validators/presence', ['exports', 'ember-cp-validations/-private/ember-validator'], function (exports, _emberValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberValidator.default.extend({
    _evType: 'presence',

    /**
     * Normalized options passed in.
     * ```js
     * validator('presence', true)
     * // Becomes
     * validator('presence', {
     *   presence: true
     * })
     * ```
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions(options = {}, defaultOptions = {}, globalOptions = {}) {
      let opts = options;

      if (typeof options === 'boolean') {
        opts = {
          presence: options
        };
      }
      return this._super(opts, defaultOptions, globalOptions);
    }
  });
});