define('torii/providers/-private/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.overrideLoadScript = overrideLoadScript;
  exports.resetLoadScript = resetLoadScript;
  exports.loadScript = loadScript;
  let alternativeLoadScript = false;

  function overrideLoadScript(state) {
    alternativeLoadScript = state;
  }

  function resetLoadScript() {
    alternativeLoadScript = false;
  }

  function loadScript(src) {
    if (alternativeLoadScript) {
      return alternativeLoadScript(src);
    }
    let scriptTag = document.createElement('script');
    let firstScriptTag = document.getElementsByTagName('script')[0];
    scriptTag.src = src;
    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
  }
});