define('ember-math-helpers/helpers/atan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.atan = atan;


  /**
   * Executes `Math.atan` on the number passed to the helper.
   *
   * ```hbs
   * {{atan a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.atan`
   * @return {number} The atan of the passed number
   */
  function atan([number]) {
    return Math.atan(number);
  }

  exports.default = Ember.Helper.helper(atan);
});