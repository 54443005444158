define("ember-cli-materialize/components/md-loader", ["exports", "ember-cli-materialize/mixins/uses-settings", "ember-cli-materialize/templates/components/md-loader"], function (_exports, _usesSettings, _mdLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_usesSettings.default, {
    layout: _mdLoader.default,
    classNameBindings: ['isBarType:progress:preloader-wrapper', 'active:active', 'size'],
    mode: null,
    percent: 0,
    size: null,
    active: true,
    color: null,

    init() {
      this._super(...arguments);

      if (!this.get('mode')) {
        this.set('mode', this.get('_mdSettings.loaderMode'));
      }

      if (!this.get('size')) {
        this.set('size', this.get('_mdSettings.loaderSize'));
      }
    },

    isBarType: Ember.computed('mode', function () {
      return ['determinate', 'indeterminate'].indexOf(this.get('mode')) >= 0;
    }),
    isDeterminate: Ember.computed('mode', function () {
      return ['determinate'].indexOf(this.get('mode'));
    }),
    barStyle: Ember.computed('mode', 'percent', function () {
      if (this.get('mode') === 'determinate') {
        return Ember.String.htmlSafe(`width: ${parseInt(this.get('percent'), 10)}%`);
      } else {
        return Ember.String.htmlSafe('');
      }
    }),
    barClassName: Ember.computed('isBarType', 'mode', function () {
      return this.get('isBarType') ? this.get('mode') : null;
    }),
    spinnerClassNames: Ember.computed('color', 'isBarType', function () {
      if (!this.get('isBarType')) {
        const color = this.get('color');

        if (!color) {
          return Ember.A(['blue', 'red', 'green', 'yellow'].map(c => `spinner-layer spinner-${c}`));
        } else {
          return Ember.A([`spinner-layer spinner-${color}-only`]);
        }
      } else {
        return Ember.A();
      }
    })
  });

  _exports.default = _default;
});