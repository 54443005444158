define('ember-math-helpers/helpers/asin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.asin = asin;


  /**
   * Executes `Math.asin` on the number passed to the helper.
   *
   * ```hbs
   * {{asin a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.asin`
   * @return {number} The asin of the passed number
   */
  function asin([number]) {
    return Math.asin(number);
  }

  exports.default = Ember.Helper.helper(asin);
});