define("ember-cli-materialize/components/md-input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-field'],
    bindAttributes: ['disabled', 'readonly', 'autofocus'],
    validate: false,
    _wasTouched: false,
    isValid: Ember.computed('_wasTouched', 'value', 'validate', 'errors', 'errors.[]', function () {
      return (Ember.isPresent(this.get('value')) || this.get('_wasTouched')) && this.get('validate') && this.get('errors') && this.get('errors.length') === 0;
    }),
    isInvalid: Ember.computed('_wasTouched', 'value', 'validate', 'errors', 'errors.[]', function () {
      return (Ember.isPresent(this.get('value')) || this.get('_wasTouched')) && this.get('validate') && this.get('errors') && this.get('errors.length') > 0;
    }),

    didInsertElement() {
      this._super(...arguments); // pad the errors element when an icon is present


      if (Ember.isPresent(this.get('icon'))) {
        this.$('> span').css('padding-left', '3rem');
      }
    },

    id: Ember.computed('elementId', function () {
      return `${this.get('elementId')}-input`;
    }),

    _setupLabel() {
      const $label = this.$('> label');

      if (Ember.isPresent(this.get('value')) && !$label.hasClass('active')) {
        $label.addClass('active');
      }
    },

    _errorString: Ember.computed('errors.[]', function () {
      return (this.get('errors') || []).join('. ');
    }),
    actions: {
      inputFocusIn(evt) {
        this.set('_wasTouched', true);
        this.sendAction('focusIn', evt);
      }

    }
  });

  _exports.default = _default;
});