define("ember-cli-materialize/helpers/bw-compat-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isOldIcon = isOldIcon;
  _exports.bwCompatIcon = bwCompatIcon;
  _exports.default = void 0;

  function isOldIcon(str) {
    return str.split(' ').filter(c => {
      return c.indexOf('mdi-') === 0;
    }).length > 0;
  }

  function bwCompatIcon(params, hash) {
    let [iconStr] = params;
    let extraClassesString = (hash || {}).extraClasses || null;
    let extraClasses = extraClassesString ? extraClassesString.split(' ') : [];

    if (isOldIcon(iconStr)) {
      return Ember.String.htmlSafe(`<i class='${Ember.A([iconStr].concat(extraClasses)).compact().join(' ')}'></i>`);
    } else {
      let classes = iconStr.split(' ');
      let icon = classes.shift();
      let classString = Ember.A(['material-icons'].concat(classes).concat(extraClasses)).compact().join(' ');
      return Ember.String.htmlSafe(`<i class='${classString}'>${icon}</i>`);
    }
  }

  var _default = Ember.Helper.helper(bwCompatIcon);

  _exports.default = _default;
});