define("ember-cli-materialize/components/md-card-collapsible", ["exports", "ember-cli-materialize/templates/components/md-card-collapsible"], function (_exports, _mdCardCollapsible) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _mdCardCollapsible.default,
    tagName: 'ul',
    classNames: ['collapsible'],
    attributeBindings: ['data-collapsible'],
    accordion: true,
    'data-collapsible': Ember.computed(function () {
      return this.get('accordion') ? 'accordion' : 'expandable';
    }),

    didInsertElement() {
      this._super(...arguments);

      this._setupCollapsible();
    },

    _setupCollapsible() {
      const accordion = this.get('accordion');
      this.$().collapsible({
        accordion
      });
    },

    _teardownCollapsible() {
      const $panelHeaders = this.$('> li > .collapsible-header');
      this.$().off('click.collapse', '.collapsible-header');
      $panelHeaders.off('click.collapse');
    },

    willDestroyElement() {
      this._super(...arguments);

      this._teardownCollapsible();
    }

  });

  _exports.default = _default;
});