define('ember-math-helpers/helpers/mult', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mult = mult;


  /**
   * Multiplies two or more numbers
   *
   * ```hbs
   * {{mult a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to multiply
   * @return {number} The product of all the passed numbers
   */
  function mult(numbers) {
    return numbers.reduce((a, b) => Number(a) * Number(b));
  }

  exports.default = Ember.Helper.helper(mult);
});