define('ember-math-helpers/helpers/mod', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mod = mod;


  /**
   * Takes the modulus of two or more numbers
   *
   * ```hbs
   * {{mod a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to modulus
   * @return {number} The modulus of all the passed numbers
   */
  function mod(numbers) {
    return numbers.reduce((a, b) => Number(a) % Number(b));
  }

  exports.default = Ember.Helper.helper(mod);
});